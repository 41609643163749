/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
}

.modal-content {
    background-color: white;
    height: 90vh;
    max-height: 600px; /* Optional max height */
    padding: 20px;
    overflow-y: auto; /* Scroll if content overflows */
    border-radius: 8px;
}
.modal-content.mobile-preview{
    width: 27%;
}
.modal-content.web-preview{
    width: 90%;
}

.modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}
