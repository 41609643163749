.addon-selection-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  .addon-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .addon-item {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .addon-name {
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  
  .addon-description {
    font-size: 14px;
    color: #666;
  }
  
  .pricing {
    margin-top: 10px;
  }
  
  .pricing-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    padding: 5px 0;
  }
  
  .price {
    font-size: 16px;
    font-weight: bold;
    color: #28a745;
  }
  
  .billing-cycle {
    font-size: 14px;
    color: #007bff;
  }
  
  .selected-addons {
    margin-top: 20px;
  }
  
  .selected-addons ul {
    list-style-type: none;
    padding: 0;
  }
  
  .selected-addons li {
    font-size: 16px;
    color: #333;
  }
  
  .loader {
    text-align: center;
    font-size: 18px;
    color: #007bff;
    font-weight: bold;
  }
  
  .addon-item input[type="checkbox"] {
    margin-right: 10px;
  }
  