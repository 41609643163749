.organisation-form {
    max-width: 80%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.organisation-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea,
select {
    width: 90%; /* Consistent width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease; /* Smooth transition */
}

.form-group input:focus,
.form-group textarea:focus,
select:focus {
    border-color: #007bff; /* Highlight on focus */
    outline: none; /* Remove outline */
}

.submit-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease; /* Smooth transition */
}

.submit-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.submit-button:active {
    transform: translateY(1px); /* Slight press effect */
}

select {
    appearance: none; /* Removes default arrow */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.5 7.5L10 12l4.5-4.5z" fill="black"/></svg>'); /* Custom down arrow */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Adjust as necessary */
    background-size: 10px; /* Size of the custom arrow */
    padding-right: 30px; /* Space for the arrow */
}
.organization-form-error{
    color: red;
}

@media (max-width: 600px) {
    .organisation-form {
        width: 95%; /* More width on small screens */
    }

    .form-group input,
    .form-group textarea,
    select {
        width: 100%; /* Full width on smaller screens */
    }
}
