/* Container styling */
.template-selector-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Title styling */
  .template-selector-title {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Image container styling */
  .template-images {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  /* Styling for individual template images */
  .template-image {
    border: 3px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s, border-color 0.3s;
  }
  
  .template-image:hover {
    transform: scale(1.05);
  }
  
  .template-image.selected {
    border-color: #007bff;
  }
  
  .template-image img {
    width: 70%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  /* Next button styling */
  .next-button {
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .next-button:hover {
    background-color: #0056b3;
  }
  