/* General Container */
.domain-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background: linear-gradient(135deg, #ffffff 30%, #f1f1f1 100%);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  h2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }
  
  /* Radio Buttons */
  .radio-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .radio-buttons label {
    font-size: 16px;
    color: #555;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .radio-buttons input {
    margin-right: 8px;
    accent-color: #007BFF;
    transition: transform 0.2s ease-in-out;
  }
  
  .radio-buttons input:checked {
    transform: scale(1.1);
  }
  
  .input-fields {
    margin-bottom: 20px;
  }
  
  /* Input Containers */
  .input-container {
    display: none;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  
  .input-container.active {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
    font-weight: 500;
  }
  
  input[type="text"],
  select {
    width: 100%;
    padding: 12px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }
  
  input[type="text"]:focus,
  select:focus {
    border-color: #007BFF;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    outline: none;
  }
  
  input[type="text"]::placeholder {
    color: #bbb;
  }
  
  select {
    padding: 12px;
    border-radius: 8px;
  }
  
  /* TLD Dropdown Styling */
  select {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .check-btn-container {
    margin-top: 20px;
  }
  
  .check-btn {
    width: 100%;
    padding: 14px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .check-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .check-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    transform: translateY(0);
  }
  
  .availability-message {
    margin-top: 20px;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .available {
    background-color: #d4edda;
    color: #155724;
  }
  
  .not-available {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .next-btn-container {
    margin-top: 30px;
    text-align: center;
  }
  
  .next-btn {
    padding: 14px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .next-btn:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  .next-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    transform: translateY(0);
  }
  
  /* Loading States */
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #007BFF;
    font-weight: 500;
  }
  
  .loading::before {
    content: "";
    border: 3px solid #f3f3f3;
    border-top: 3px solid #007BFF;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  