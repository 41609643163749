.image-selection {
    text-align: center;
    margin: 20px;
}

.image-container {
    display: flex;
    flex-wrap: wrap;  
    gap: 10px;  
    justify-content: center; 
}

.image-card {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
    overflow: hidden;
    transition: border-color 0.3s, transform 0.3s;
}

.image-card:hover {
    transform: scale(1.05); 
}

.image-card.selected {
    border-color: blue; 
}

.image {
    width: 150px;
    height: 150px;
    object-fit: cover; 
}
.selected-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 15px; 
    background-color: #f9f9f9;
    margin-bottom: 10px;
  }
  
  .selected-image {
    position: relative;
    border-radius: 10px; 
    overflow: hidden; 
  }
  
  .selected-image-thumbnail {
    width: 100px; 
    height: 100px; 
    object-fit: cover; 
    border-radius: 10px; 
  }
  
  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 11px;
    border-radius: 50%;
  }
  
  .remove-button:hover {
    background: rgba(255, 0, 0, 1);
  }

  
  
