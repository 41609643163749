/* Container */
.ServiceRepeater {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* Heading */
.ServiceRepeater h2 {
    text-align: center;
    color: #333;
}

/* Service item container */
.service-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Input fields */
.service-item input[type="text"],
.service-item textarea {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    resize: vertical;
}

/* Buttons */
.service-item button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.service-item button:hover {
    background-color: #0056b3;
    color: #fff;
}

button.generate-description {
    background-color: #4caf50;
    color: white;
}

button.remove {
    background-color: #f44336;
    color: white;
}

/* Add New Service button */
button.add-service {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

button.add-service:hover {
    background-color: #0056b3;
}
