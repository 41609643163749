/* Preview.css */

.preview-buttons {
  display: flex;
  justify-content: space-around;
}

/* Container for the buttons */
.preview-button {
  display: inline-block;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-style: dashed;
  padding: 45px 90px;
  border-width: 3px;
  border-radius: 10px
}

.mobile-preview{
  border-color: #3ec3eb;
}
.web-preview{
  border-color: #27da76;
}


/* Styling for the icon images */
.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image in the button */
  width: 70%; /* Adjust icon size to fit inside the button */
  height: auto; /* Maintain aspect ratio of the icon */
}
